import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: { // A
          base: '#2d4198',
          darken1: '#2d3661', // R
          lighten1: '#dde9ff' // C
        },
        primaryBtnText: { // A'
          base: '#FFFFFF'
        },
        secondary: { // B
          base: '#F9C618',
          darken1: '#dbb942' // D
        },
        secondaryBtnText: { // B'
          base: '#424242'
        },
        headerBack: { // E
          base: '#2d4198'
        },
        headerText: { // F
          base: '#FFFFFF'
        },
        logoutBtnText: { // G
          base: '#FFFFFF'
        },
        topSchoolNamePc: { // H
          base: '#FFFFFF'
        },
        topSecondaryBtn: { // I
          base: '#F9C618'
        },
        topSecondaryBtnText: { // J
          base: '#424242'
        },
        topPrimaryBtn: { // K
          base: '#2d4198'
        },
        topPrimaryBtnText: { // L
          base: '#FFFFFF'
        },
        topBtnUpText: { // M
          base: '#FFFFFF'
        },
        primaryOutLineBtnText: { // N
          base: '#2d4198'
        },
        secondaryOutLineBtnText: { // O
          base: '#424242'
        },
        topBtnUpTextSp: { // P
          base: '#FFFFFF'
        },
        topBtnBackSp: { // Q
          base: '#0C184D'
        },
        eventHeaderBack: {
          base: '#F9C618'
        },
        eventHeaderText: {
          base: '#2d4198'
        },
        eventTopBorderSub: {
          base: '#F9C618'
        },
        accent: '#2d4198', // datepickerなどの色
        formBackground: '#F6f6f6'
      },
      dark: {
        primary: '#FF5252',
        secondary: '#43A047',
        accent: '#162988',
        formBackground: '#F6f6f6'
      }
    }
  }
})
