import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import utility from './js/utility'
import api from './js/api'
import apiEvent from './js/api_event'
import InfiniteLoading from 'vue-infinite-loading'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './directive/index.js'
import VueLazyload from 'vue-lazyload'
import VueVirtualScroller from 'vue-virtual-scroller'

import moment from 'vue-moment'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueClipboard from 'vue-clipboard2'
import Amplify, * as AmplifyModules from '@aws-amplify/core'
import AmplifyPlugin from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

// window.LOG_LEVEL = 'DEBUG'

Vue.use(VueQuillEditor)

Vue.use(InfiniteLoading)
Vue.use(VueAxios, axios)
Vue.use(VueVirtualScroller)

Vue.use(VueLazyload, {
  attempt: 1,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

const momentOpt = require('moment')
require('moment/locale/en-gb')
Vue.use(moment, { momentOpt })

Vue.config.productionTip = false
Vue.mixin(utility)
Vue.mixin(api)
Vue.mixin(apiEvent)

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Amplify.configure({
  aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
  Auth: {
    identityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_AWS_COGNITO_REGION,
    identityPoolRegion: process.env.VUE_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN_DOMAIN,
      scope: ['phone', 'email', 'openid', 'profile'],
      redirectSignIn: process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN_CALLBACK_URL,
      redirectSignOut: process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN_CALLBACK_URL,
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  },
  API: {
    endpoints: [
      {
        name: 'public',
        endpoint: process.env.VUE_APP_AWS_API_BASE_URL
      },
      {
        name: 'private',
        endpoint: process.env.VUE_APP_AWS_API_BASE_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` }
        }
      },
      {
        name: 'eventPublic',
        endpoint: process.env.VUE_APP_AWS_EVENT_API_BASE_URL
      },
      {
        name: 'eventPrivate',
        endpoint: process.env.VUE_APP_AWS_EVENT_API_BASE_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` }
        }
      },
      {
        name: 'eventPayment',
        endpoint: process.env.VUE_APP_AWS_EVENT_PAYMENT_API_BASE_URL
      },
      {
        name: 'eventPrivatePayment',
        endpoint: process.env.VUE_APP_AWS_EVENT_PAYMENT_API_BASE_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` }
        }
      },
      {
        name: 'integration',
        endpoint: process.env.VUE_APP_AWS_API_INTEGRATION_URL + '/' + process.env.VUE_APP_AWS_API_ENV,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` }
        }
      },
      {
        name: 'integrationNotAuth',
        endpoint: process.env.VUE_APP_AWS_API_INTEGRATION_URL + '/' + process.env.VUE_APP_AWS_API_ENV
      },
      {
        name: 'integrationEvent',
        endpoint: process.env.VUE_APP_AWS_API_INTEGRATION_EVENT_URL + '/' + process.env.VUE_APP_AWS_API_ENV,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` }
        }
      },
      {
        name: 'integrationNotAuthEvent',
        endpoint: process.env.VUE_APP_AWS_API_INTEGRATION_EVENT_URL + '/' + process.env.VUE_APP_AWS_API_ENV
      },
      {
        name: 'integrationEventPayment',
        endpoint: process.env.VUE_APP_AWS_API_PAYMENT_INTEGRATION_EVENT_URL + '/' + process.env.VUE_APP_AWS_API_ENV
      },
      {
        name: 'integrationEventPrivatePayment',
        endpoint: process.env.VUE_APP_AWS_API_PAYMENT_INTEGRATION_EVENT_URL + '/' + process.env.VUE_APP_AWS_API_ENV,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` }
        }
      },
      {
        name: 'integrationPayment',
        endpoint: process.env.VUE_APP_AWS_API_PAYMENT_INTEGRATION_BASE_URL + '/' + process.env.VUE_APP_AWS_API_ENV,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` }
        }
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_AWS_S3_BUCKET,
      region: process.env.VUE_APP_AWS_S3_REGION
    }
  }
})

// Analytics.autoTrack('pageView', {
//   // REQUIRED, turn on/off the auto tracking
//   enable: true,
//   // OPTIONAL, the event name, by default is 'pageView'
//   eventName: 'pageView',
//   // OPTIONAL, the attributes of the event, you can either pass an object or a function
//   // which allows you to define dynamic attributes
//   attributes: {
//     attr: 'attr'
//   },
//   // when using function
//   // attributes: () => {
//   //    const attr = somewhere();
//   //    return {
//   //        myAttr: attr
//   //    }
//   // },
//   // OPTIONAL, by default is 'multiPageApp'
//   // you need to change it to 'SPA' if your app is a single-page app like React
//   type: 'multiPageApp',
//   // OPTIONAL, the service provider, by default is the AWS Pinpoint
//   provider: 'AWSPinpoint',
//   // OPTIONAL, to get the current page url
//   getUrl: () => {
//     // the default function
//     return window.location.origin + window.location.pathname
//   }
// })

Vue.use(AmplifyPlugin, AmplifyModules)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
