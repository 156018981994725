import Vue from 'vue'

Vue.directive('infi-scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      const pageLoad = binding.value.pageLoad
      if (binding.value.function(el, pageLoad, evt)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
})

Vue.directive('infi-scroll-sp', {
  inserted: function (el, binding) {
    const f = function (evt) {
      const pageLoad = binding.value.pageLoad
      if (binding.value.function(pageLoad, evt)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})
