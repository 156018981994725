<template>
  <v-app id="app" v-resize="onResize">
    <router-view />

    <v-dialog max-width="512px" persistent v-model="$store.state.editDeleteDialog.show">
      <EditDeleteDialog></EditDeleteDialog>
    </v-dialog>

    <v-snackbar v-model="$store.state.snackbar.show" top persistent :timeout="6500" :color="$store.state.snackbar.color" class="z-index-300">
      {{$store.state.snackbar.text}}
    </v-snackbar>

    <v-overlay :value="$store.state.loading.length !== 0" :z-index="vDialogCheck() === 0 ? 190 : 210">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import 'normalize.css'
import '@/assets/icomoon/style.css'
import './css/style.css'
import 'leaflet/dist/leaflet.css'

import EditDeleteDialog from '@/components/modal/EditDelete'

export default {
  name: 'app',
  components: {
    EditDeleteDialog
  },
  created: function () {
    const self = this
    this.$store.commit('startLoading')
    this.globalLoginConfirm().then(async function (response) {
      // if (response.username === response.attributes.email) {
      //   this.globalLogoutNoSnack()
      //   this.$router.push('/')
      //   this.$store.commit('finishLoading')
      //   return
      // }
      self.$store.commit('setLogined', !!response)
      if (response) {
        console.log(response.signInUserSession.idToken.payload['custom:JleagueID'], response.signInUserSession.idToken.payload)
        const mail = response.attributes ? response.attributes.email : response.signInUserSession.idToken.payload.email
        const jid = response.signInUserSession.idToken.payload['custom:JleagueID'] ? response.signInUserSession.idToken.payload['custom:JleagueID'] : null
        self.$store.commit('setLoginData', {
          id: response.username,
          userId: '',
          name: '',
          mail: mail,
          jid: jid
        })
      }
      self.$store.commit('finishLoading')
    }, e => {
      self.$store.commit('finishLoading')
    }).catch(e => {
      self.$store.commit('finishLoading')
    })
    window.gmoKey = process.env.VUE_APP_AWS_GMO_KEY
  },
  mounted: function () {
    if (window.localStorage.getItem('bankRegisterCallbackPage')) {
      console.log(window.localStorage.getItem('bankRegisterCallbackPage'))
      this.$router.push(window.localStorage.getItem('bankRegisterCallbackPage'))
      window.localStorage.setItem('gmoCallBack', '1')
      window.localStorage.setItem('bankRegisterCallbackPage', '')
    }
    console.log(document.getElementsByClassName('v-dialog'))
  },
  methods: {
    onResize: function () {
      this.$store.commit('setDeviceType', this.$vuetify.breakpoint.thresholds.sm)
    },
    vDialogCheck: function () {
      const tmp = window.document.getElementsByClassName('v-dialog')
      return tmp.length
    }
  },
  watch: {
    'window.document': function (value) {
      console.log(value.getElementsByClassName('v-dialog'))
    }
  }
}
</script>
